import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/components/LayoutPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "className": "hero"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "992px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/24b274de3a54ee0314653617085b8afc/c161a/gold-machine.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.451612903225815%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/24b274de3a54ee0314653617085b8afc/c4d1c/gold-machine.webp 248w", "/static/24b274de3a54ee0314653617085b8afc/488b2/gold-machine.webp 496w", "/static/24b274de3a54ee0314653617085b8afc/f4b34/gold-machine.webp 992w", "/static/24b274de3a54ee0314653617085b8afc/25c51/gold-machine.webp 1488w", "/static/24b274de3a54ee0314653617085b8afc/83a13/gold-machine.webp 1920w"],
              "sizes": "(max-width: 992px) 100vw, 992px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/24b274de3a54ee0314653617085b8afc/577a4/gold-machine.png 248w", "/static/24b274de3a54ee0314653617085b8afc/21d86/gold-machine.png 496w", "/static/24b274de3a54ee0314653617085b8afc/c9b7e/gold-machine.png 992w", "/static/24b274de3a54ee0314653617085b8afc/f09ac/gold-machine.png 1488w", "/static/24b274de3a54ee0314653617085b8afc/c161a/gold-machine.png 1920w"],
              "sizes": "(max-width: 992px) 100vw, 992px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/24b274de3a54ee0314653617085b8afc/c9b7e/gold-machine.png",
              "alt": "test",
              "title": "test",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      